@tailwind base;
@tailwind components;
@tailwind utilities;


html {
  direction: rtl;
}

body {
  background-color: #f4f6f8;
}


.activeTab::after {
  content: "";
  width: 100%;
  background-color: #00abc1;
  height: 4px;
  border-radius: 20px;
}
